import * as React from 'react';

import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import IntroPage from '../../components/ContentType/IntroPage/IntroPage';
import TextVisuelTwoCol from '../../components/ContentType/TextVisuelTwoCol/TextVisuelTwoCol';
import VisuelTextOverlappedBlock from '../../components/ContentType/VisuelTextOverlappedBlock/VisuelTextOverlappedBlock';
import KeyNumbers from '../../components/KeyNumbers/KeyNumbers';
import PageBanner from '../../components/PageBanner/PageBanner';
import ScrollPageNav from '../../components/ScrollPageNav/ScrollPageNav';
import Seo from '../../components/Seo/Seo';
import SliderComponent from '../../components/SliderComponent/SliderComponent';
import Layout from '../../Layout';
import DiscoverOfferBox from '../../Molecules/DiscoverOfferBox/DiscoverOfferBox';
import ShapeBackground from '../../Molecules/ShapeBackground/ShapeBackground';
import StickyJobApply from '../../Molecules/StickyJobApply/StickyJobApply';
import TitlePage from '../../Molecules/TitlePage/TitlePage';
import TitleSection from '../../Molecules/TitleSection/TitleSection';
import './talents.scss';

let classNames = require('classnames');

const AboutUsPage = () => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: {eq: "slider-image.png"}) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
          fixed(width: 373, height: 503) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `);
  const ListsWhyTOjoin = [
    {
      title: 'Vous cherchez une mission qui a du sens.',
      text: '<p>Les collaborateurs de Colas partagent tous les mêmes valeurs, la même culture, la même mission : imaginer, construire et entretenir des infrastructures de transport de façon responsable, à partir de leur ancrage local à travers le monde. Objets structurants du territoire, éléments de liaison entre les hommes et supports de leurs activités, les infrastructures de transports sont nos réseaux sociaux. Nous rejoindre, c’est participer au quotidien à construire la mobilité de demain.</p>',
      visuel: data.file.childImageSharp
    },
    {
      title: 'Vous cherchez une mission qui a du sens.',
      text: '<p>Les collaborateurs de Colas partagent tous les mêmes valeurs, la même culture, la même mission : imaginer, construire et entretenir des infrastructures de transport de façon responsable, à partir de leur ancrage local à travers le monde. Objets structurants du territoire, éléments de liaison entre les hommes et supports de leurs activités, les infrastructures de transports sont nos réseaux sociaux. Nous rejoindre, c’est participer au quotidien à construire la mobilité de demain.</p>',
      visuel: data.file.childImageSharp
    },
    {
      title: 'Vous cherchez une mission qui a du sens.',
      text: '<p>Les collaborateurs de Colas partagent tous les mêmes valeurs, la même culture, la même mission : imaginer, construire et entretenir des infrastructures de transport de façon responsable, à partir de leur ancrage local à travers le monde. Objets structurants du territoire, éléments de liaison entre les hommes et supports de leurs activités, les infrastructures de transports sont nos réseaux sociaux. Nous rejoindre, c’est participer au quotidien à construire la mobilité de demain.</p>',
      visuel: data.file.childImageSharp
    },
    {
      title: 'Vous cherchez une mission qui a du sens.',
      text: '<p>Les collaborateurs de Colas partagent tous les mêmes valeurs, la même culture, la même mission : imaginer, construire et entretenir des infrastructures de transport de façon responsable, à partir de leur ancrage local à travers le monde. Objets structurants du territoire, éléments de liaison entre les hommes et supports de leurs activités, les infrastructures de transports sont nos réseaux sociaux. Nous rejoindre, c’est participer au quotidien à construire la mobilité de demain.</p>',
      visuel: data.file.childImageSharp
    },
    {
      title: 'Vous cherchez une mission qui a du sens.',
      text: '<p>Les collaborateurs de Colas partagent tous les mêmes valeurs, la même culture, la même mission : imaginer, construire et entretenir des infrastructures de transport de façon responsable, à partir de leur ancrage local à travers le monde. Objets structurants du territoire, éléments de liaison entre les hommes et supports de leurs activités, les infrastructures de transports sont nos réseaux sociaux. Nous rejoindre, c’est participer au quotidien à construire la mobilité de demain.</p>',
      visuel: data.file.childImageSharp
    },
    {
      title: 'Vous cherchez une mission qui a du sens.',
      text: '<p>Les collaborateurs de Colas partagent tous les mêmes valeurs, la même culture, la même mission : imaginer, construire et entretenir des infrastructures de transport de façon responsable, à partir de leur ancrage local à travers le monde. Objets structurants du territoire, éléments de liaison entre les hommes et supports de leurs activités, les infrastructures de transports sont nos réseaux sociaux. Nous rejoindre, c’est participer au quotidien à construire la mobilité de demain.</p>',
      visuel: data.file.childImageSharp
    },
    {
      title: 'Vous cherchez une mission qui a du sens.',
      text: '<p>Les collaborateurs de Colas partagent tous les mêmes valeurs, la même culture, la même mission : imaginer, construire et entretenir des infrastructures de transport de façon responsable, à partir de leur ancrage local à travers le monde. Objets structurants du territoire, éléments de liaison entre les hommes et supports de leurs activités, les infrastructures de transports sont nos réseaux sociaux. Nous rejoindre, c’est participer au quotidien à construire la mobilité de demain.</p>',
      visuel: data.file.childImageSharp
    }
  ];

  const slider_why_join_settings = {
    className: 'hash_dots_slider',
    centerMode: false,
    infinite: false,
    centerPadding: '5px',
    slidesToShow: 1,
    speed: 500,
    initialSlide: 0,
    arrows: true,
    dots: true,
    customPaging: function (i) {
      return (
        <a>
          {`#${(i + 1)}`}
          <svg width="36.961" height="6" viewBox="0 0 36.961 6"><path d="M31.654 4.412A10.487 10.487 0 0126.478 6h-16a10.481 10.481 0 01-5.175-1.588A39.3 39.3 0 01-.001 0h36.963a39.323 39.323 0 01-5.308 4.412z" fill="#ffed00"></path></svg>
        </a>
      );
    },
  };

  return (
    <Layout>
      <Seo
        title="Colas Our Talents"
        description="Description de la page About us"
      />
      <div className={classNames('page_template', 'zero_bottom_padding', 'page_talents')}>
        <ScrollPageNav
          data={[
            {
              title: 'Edito',
              section: 'year_twenty_twenty'
            },
            {
              title: 'Nous sommes faits pour nous rencontrer si…',
              section: 'section_lists_why_to_join'
            },
            {
              title: 'Notre politique RH',
              section: 'section_human_resources'
            },
            {
              title: 'Key Numbers',
              section: 'key_numbers_component'
            }
          ]}
        />
        <PageBanner visuel={data.file.childImageSharp.fluid} scroll={true}>
          <div className="wrapper_page">
            <Breadcrumb
              page_banner={true}
              data={{
                grandParentPage: { title: 'Acceuil', url: '/' },
                parentPage: { title: 'Finance', url: '/' },
                currentPage: { title: 'Réunion des analystes', url: '/finance/analyst-meeting' }
              }}
            />
            <TitlePage
              color="color_white"
              title="Talents"
              description="Depuis 90 ans, Colas innove pour construire des infrastructures toujours plus respectueuses de l’environnement !"
            />
          </div>
        </PageBanner>

        <StickyJobApply
          text="Découvrez <strong>nos offres d’emploi</strong>"
          buttonLabel="Postulez"
          buttonLink='/'
        />

        <IntroPage
          title="Edito"
          subtitle="La force du Groupe réside principalement dans les atouts suivants :"
          visuel={data.file.childImageSharp}
          text={null}
          size="wrapper" //border
          quote={{
            text: 'quote Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet.',
            name: 'Prénom Nom'
          }}
        />
        <section className={classNames('section_content', 'section_lists_why_to_join')}>
          <ShapeBackground
            top="right"
            bottomInfinite={true}
            color="catskill_white"
          >
            <div className="wrapper_page">
              <TitleSection
                title="Nous sommes faits pour nous rencontrer si…"
                type="arrow"
              />
              <div className="only_mobile">
                <SliderComponent settings={slider_why_join_settings}>
                  {ListsWhyTOjoin.map((whyToJoinItem, i) => (
                    <div key={i}>
                      <div className="upper_text">
                        <p className="title_hash">{whyToJoinItem.title}</p>
                        <p>{whyToJoinItem.text}</p>
                      </div>
                      <div className="visuel">
                        {whyToJoinItem?.visuel &&
                          <Img
                            style={{ width: '100%' }}
                            imgStyle={{ objectFit: 'cover' }}
                            fixed={whyToJoinItem.visuel.fixed}
                          />
                        }
                      </div>
                    </div>
                  ))}
                </SliderComponent>
              </div>
              <div className="only_tablet_desktop">
                {ListsWhyTOjoin.map((whyToJoinItem, i) => (
                  <TextVisuelTwoCol
                    key={i}
                    orderInverse={i % 2 == 0 ? false : true}
                    extremeLeft={i === 0 ? true : false}
                    visuel={whyToJoinItem.visuel}
                    titleHash={true}
                    title={'#' + (i + 1) + ' ' + whyToJoinItem.title}
                    text={whyToJoinItem.text}
                  />
                ))}
              </div>
            </div>
          </ShapeBackground>
        </section>

        <section className={classNames('section_content', 'section_human_resources')}>
          <ShapeBackground
            top="left"
            bottom="left"
            color="bleu"
          >
            <div className="wrapper_page">
              <DiscoverOfferBox
                visuel={data.file.childImageSharp}
              />
            </div>
            <div className="wrapper_page_xs">
              <TitleSection
                title="Notre politique RH"
                description="Attirer, développer et fidéliser les talents par l’excellence managériale."
                type="arrow"
                h2color='white'
                h4color="yellow"
              />
            </div>
            <div className="wrapper_page">
              <VisuelTextOverlappedBlock
                extremeLeft={true}
                visuel={data.file.childImageSharp}
                alt=""
                bgColor="color_sky_bleu"
                title="Respect, Partage et Audace."
                text="Ces valeurs forgent l’identité de Colas et prennent tout leur sens au quotidien sur nos chantiers et nos sites."
              />
            </div>
            <div className="wrapper_page_xs">
              <TextVisuelTwoCol
                orderInverse={true}
                visuel={data.file.childImageSharp}
                extactHalf={true}
                colorTitle="color_white"
                colorDescription="color_white"
                text={null}
                textList={[
                  {
                    text: 'd’attirer, sur la base de la diversité en termes de nationalité, de genre et de pensée, de nouveaux talents, en mettant en avant la marque et les valeurs (#Respect, #Partage, #Audace) de Colas.'
                  },
                  {
                    text: 'd’attirer, sur la base de la diversité en termes de nationalité, de genre et de pensée, de nouveaux talents, en mettant en avant la marque et les valeurs (#Respect, #Partage, #Audace) de Colas.'
                  },
                  {
                    text: 'd’attirer, sur la base de la diversité en termes de nationalité, de genre et de pensée, de nouveaux talents, en mettant en avant la marque et les valeurs (#Respect, #Partage, #Audace) de Colas.'
                  }
                ]}
              />
            </div>
          </ShapeBackground>
        </section>
        <KeyNumbers
          textColor="white"
          datas={
            [
              {
                number: '12,3',
                title: 'Mds€ De Chiffre d’Affaires'
              },
              {
                number: '3 800',
                title: 'Implantations dans le monde'
              },
              {
                number: '54 600',
                title: 'COLLABORATEURS <span>(AU 31 DÉCEMBRE 2020)</span> EFFECTIF MOYEN SUR L’ANNÉE : 59 400'
              },
              {
                number: '3 000',
                title: 'UNITÉS DE PRODUCTION ET RECYCLAGE DE MATÉRIAUX'
              }
            ]
          }
        />
      </div>
    </Layout>
  );
};

export default AboutUsPage;
